import LandingDiv from "../LandingDiv/LandingDiv";
import Text from "../Text/Text";
import "./About.scss"

export default function About() {
    return (
        <div id="About">
            <LandingDiv title_id={32} subtitle_id={33} />
            <div className="content contrasted">
                <div>
                    <h2 className="content-title big-title-font-size">Notre philosophie</h2>
                    <p className="content-text"><Text text_id={34} /></p>
                    <p className="content-text"><Text text_id={35} /></p>
                    <p className="content-text"><Text text_id={36} /></p>
                    <p className="content-text"><Text text_id={37} /></p>
                </div>
            </div>
        </div>
    );
}