import Text from "../Text/Text";
import "./Home.scss";
import Argument from "./Argument/Argument";
import LandingDiv from "../LandingDiv/LandingDiv";

export default function Home() {

    return (
        <div id="Home">
            <LandingDiv very_big_title={1} title_id={1} subtitle_id={2} />
            <div className="contrasted-container contrasted">
                <div className="what-we-do">
                    <h2 className="what-we-do-title title-font-size"><Text text_id={4} /></h2>
                    <ul>
                        <li><Text text_id={5} /></li>
                        <li><Text text_id={6} /></li>
                        <li><Text text_id={7} /></li>
                        <li><Text text_id={8} /></li>
                        <li><Text text_id={9} /></li>
                    </ul>
                </div>
            </div>
            <div className="why-would-you-buy">
                <h2 className="why-would-you-buy-title big-title-font-size"><Text text_id={16} /></h2>
                <Argument title={<Text text_id={17} />}>
                    <Text text_id={18} />
                    <Text text_id={19} />
                </Argument>
                <Argument title={<Text text_id={20} />}>
                    <Text text_id={21} />
                    <Text text_id={22} />
                </Argument>
                <Argument title={<Text text_id={23} />}>
                    <Text text_id={24} />
                    <Text text_id={25} />
                </Argument>
                <Argument title={<Text text_id={26} />}>
                    <Text text_id={27} />
                    <Text text_id={28} />
                </Argument>
                <Argument title={<Text text_id={29} />}>
                    <Text text_id={30} />
                    <Text text_id={31} />
                </Argument>
            </div>
            <div className="contrasted-container contrasted">
                <div className="what-you-must-know">
                    <h2 className="what-you-must-know-title title-font-size"><Text text_id={10} /></h2>
                    <ul>
                        <li><Text text_id={11} /></li>
                        <li><Text text_id={12} /></li>
                        <li><Text text_id={13} /></li>
                        <li><Text text_id={14} /></li>
                        <li><Text text_id={15} /></li>
                    </ul>
                </div>
            </div>
        </div >
    );
}