import EllipseBG from "../EllipseBG/EllipseBG";
import SeeMore from "../SeeMore/SeeMore";
import Text from "../Text/Text";
import "./LandingDiv.scss";

export default function LandingDiv(props: { title_id: number, subtitle_id: number, very_big_title?: number }) {
    return (
        <div className="landing-div">
            <div className="titles-container">
                <h1 className={"title " + (props.very_big_title ? "very-big-title-font-size " : " big-title-font-size ")}><Text text_id={props.title_id} /></h1>
                <h2 className="subtitle title-font-size"><Text text_id={props.subtitle_id} /></h2>
            </div>
            <SeeMore />
            <EllipseBG />
        </div>
    )
}